
body {
    margin: 0px;
    padding: 0px;
    font-family: Helvetica;
}

a:hover {
    text-decoration: none;
}

.spare-dvs {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    text-align: center;
}

.logos-dv {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    text-align: center;
}

.logo-image {
    max-width: 280px;
    margin: 0px auto;
    padding: 0px;
}

.logo-image a {
    outline: none;
}

.logo-image img {
    max-width: 100%;
    width: 160px;
}

.spare-dvs1 {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    text-align: center;
}

.spare-dvs h1 {
    color: #000;
    margin: 0px;
    font-weight: 550;
    font-size: 30px;
    padding: 10px;
}

.spare-dvs h2 {
    color: #000;
    margin: 0px;
    font-weight: 550;
    font-size: 26px;
    padding: 10px;
}

.spare-dvs h3 {
    color: #000;
    margin: 0px;
    font-weight: 500;
    font-size: 22px;
    padding: 10px;
}

.spare-dvs h4 {
    color: #000;
    margin: 0px;
    font-weight: 500;
    font-size: 18px;
}

.spare-dvs p {
    color:#000;
    margin: 0px;
    font-weight: 300;
    font-size: 12px;
}

.spare h1 {
    color: #000;
    margin: 0px;
    font-weight: 550;
    font-size: 30px;
    padding: 10px 0px;
}

.spare h2 {
    color: #000;
    margin: 0px;
    font-weight: 550;
    font-size: 26px;
    padding: 10px 0px;
}

.spare h3 {
    color: #000;
    margin: 0px;
    font-weight: 500;
    font-size: 22px;
    padding: 10px 0px;
}

.spare h4 {
    color: #000;
    margin: 0px;
    font-weight: 500;
    font-size: 18px;
    padding: 5px 0px;
}

.spare p {
    color:#000;
    margin: 0px;
    font-weight: 300;
    font-size: 14px;
    padding: 5px 0px;
}

.spare-dvs a {
    background-color: #4C6EF8;
    display: block;
    padding: 20px 0px;
    border-radius: 55px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin: 40px auto 0px;
    border: 1px solid #4C6EF8;
    transition: all linear 0.5s;
    width: 100%;
    max-width: 280px;
}

.spare-dvs a:hover {
    background-color: #4C6EF8;
}

.log-form {
    width: 100%;
    float: left;
    margin: 55px 0px 0px;
    padding: 0px;
}

.log-form a {
    margin: 40px auto 0px;
}

.custom-carousel-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.custom-carousel-control {
    background: none;
    border: none;
}

.custom-carousel-control img {
    width: 30px;
    height: auto;
}

.custom-carousel-indicators {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.custom-signup-btn {
    background-color: white !important;
    color: black !important;
    padding: 10px 20px;
    font-weight: bold;
}

.text-center .log-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.progress-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
}


.email-dvs {
    width: 100%;
    margin: 10px 0px 0px;
    padding: 0px;
    float: left;
}

.email-dvs form {
    width: 100%;
    max-width: 280px;
    margin: 0px auto;
}

.email-dvs form p input {
    width: 100%;
    /*margin: 30px 0px 0px;*/
    /*padding: 5px 0px;*/
    float: left;
    border: none;
    border-bottom: 1px solid #b7b7b7;
}

.email-dvs form .btn-enter input {
    background-color: #4C6EF8;
    display: block;
    padding: 20px 0px;
    border-radius: 55px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin: 30px auto 0px;
    border: 1px solid #4C6EF8;
    transition: all linear 0.5s;
    width: 100%;
    max-width: 280px;
    transition: all linear 0.5s;
}

.email-dvs form .btn-enter input:hover {
    background-color: #4C6EF8;
}

.verify-dvs {
    width: 100%;
    max-width: 320px;
    margin: 50px auto 0px;
}

.verfy-txt {
    width: 100%;
    float: left;
}

.verfy-txt p {
    padding: 0px;
    font-size: 15px;
    color: #666;
    text-align: left;
}

.verfy-txt p strong {
    font-weight: 500;
    color: #000;
    font-weight: 600;
}

.verfy-txt a {
    margin: 15px 0px;
    font-size: 15px;
    text-align: left;
    float: left;
}

.verify-inputs {
    width: 100%;
    margin: 10px 0px 0px;
    padding: 0px;
    float: left;
}

.verify-inputs p {
    padding: 0px;
    font-size: 15px;
    color: #000;
    text-align: left;
    font-weight: 500;
    margin: 0px;
}

.verify-inputs .verfct-inpt {
    width: 100%;
    float: left;
    margin: 5px 0px 0px;
    padding: 7px 0px;
    border: 1px solid #b7b7b7;
    font-size: 15px;
    border: none;
    border-bottom: 1px solid #b7b7b7;
}

.send-btn {
    background-color: #4C6EF8;
    display: block;
    padding: 20px 0px;
    border-radius: 55px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin: 80px auto 0px;
    border: 1px solid #4C6EF8;
    transition: all linear 0.5s;
    width: 100%;
    max-width: 280px;
    transition: all linear 0.5s;
}

.send-btn:hover {
    background-color: #4C6EF8;
}

.ntwrk-dvs {
    width: 100%;
    float: left;
    margin: 0px 0px 0px;
    padding: 0px;
}

.ntwrk-dvs h1 {
    color: #000;
    margin: 0px 0px 40px;
    font-weight: 500;
    font-size: 48px;
}

.ntwrk-dvs h5 {
    color: #000;
    margin: 35px 0px 0px;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
}

.terms-dv h6 {
    color: #000;
    margin: 35px 0px 0px;
    font-weight: 500;
    font-size: 18px;
}

.terms-dv {
    width: 100%;
    margin: 25px 0px 0px;
    padding: 0px;
    float: left;
}

.terms-dv p {
    margin: 5px 0px 0px;
    padding: 0px;
    font-size: 14px;
}

.terms-dv a {
    background-color: #4C6EF8;
    display: block;
    padding: 20px 0px;
    border-radius: 55px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin: 70px auto 0px;
    border: 1px solid #4C6EF8;
    transition: all linear 0.5s;
    width: 100%;
    max-width: 280px;
    transition: all linear 0.5s;
}

.terms-dv a:hover {
    background-color: #4C6EF8;
}

.spare-dvs h1 sup {
    font-size: 22px;
    letter-spacing: -9px;
    top: -1em;
}

.lctn-dvs {
    margin-top: 20px;
    width: 100%;
    float: left;
}

.lctn-dvs a {
    margin: 10px auto 0px;
    border-radius: 15px;
}

.cash-dvs {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    text-align: center;
}

.cash-dvs h1 {
    color: #000;
    margin: 0px;
    font-weight: 500;
    font-size: 48px;
}

.cash-dvs p {
    margin: 10px 0px;
    padding: 0px;
    font-size: 16px;
    font-weight: 500;
}

.cshrdy-dv {
    margin-top: 50px;
    width: 100%;
    float: left;
}

.cshrdy-dv p .csh-input {
    width: 100%;
    margin: 40px 0px 0px;
    padding: 5px 0px;
    float: left;
    border: none;
    border-bottom: 1px solid #b7b7b7;
}

.cshrdy-dv form {
    width: 100%;
    max-width: 280px;
    margin: 0px auto;
}

.cshrdy-dv .btn-cash {
    margin-top: 0px;
    float: left;
    width: 100%;
}

.cshrdy-dv .btn-cash input {
    background-color: #4C6EF8;
    display: block;
    padding: 20px 0px;
    border-radius: 55px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin: 55px auto 0px;
    border: 1px solid #4C6EF8;
    transition: all linear 0.5s;
    width: 100%;
    max-width: 280px;
}

.cshrdy-dv .btn-cash input:hover {
    background-color: #4C6EF8;
}

.zip-inpt {
    width: 100%;
    margin: 50px auto 0px;
    padding: 5px 0px;
    border: none;
    border-bottom: 1px solid #b7b7b7;
    max-width: 280px;
    width: 100%;
}

.accpt-cndtn {
    width: 100%;
    margin: 20px 0px 0px;
    float: left;
}

.accpt-cndtn input {
    float: left;
}

.accpt-cndtn span {
    font-size: 14px;
    margin-top: -13px;
    float: left;
    margin-left: 10px;
    color: #666;
}

.alrdy-acnt {
    width: 100%;
    float: left;
    margin: 20px 0px 0px;
    padding: 15px 0px 0px;
    text-align: center;
    border-top: 1px solid #cecece;
    font-size: 15px;
    color: #666;
}

.sign-anchr {
    color: #4C6EF8 !important;
    background: none !important;
    padding: 0px !important;
    margin: 0px !important;
    max-width: none !important;
    width: auto !important;
    border: none !important;
    display: inline-block !important;
    font-size: 15px !important;
}

.accpt-dvs {
    width: 100%;
    margin: 20px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 10px;
    border: 1px solid #e8e8e8;
    border-radius: 15px;
    margin-bottom: 10px
}

.accpt-dvs p {
    color: #666;
    font-size: 14px;
    margin: 0px;
}

.accpt-dvs img {
    height: 30px;
    width: auto;
}

.card-dtls {
    width: 100%;
    margin: 20px 0px 0px;
    padding: 0px;
    float: left;
}

.card-dtls h3 {
    margin: 0px;
    padding: 0px 0px 10px;
    text-align: left;
    /* border-bottom: 1px solid #b7b7b7; */
}

.card-dtls form {
    width: 100%;
    float: left;
    margin: 0px;
    padding: 0px;
}

.card-dtls form p {
    width: 100%;
    float: left;
    margin: 20px 0px 0px;
}

.card-dtls form label {
    width: 100%;
    float: left;
    text-align: left;
    color: #666;
    font-size: 15px;
}

.card-dtls form p input {
    width: 100%;
    margin: 0px 0px 0px;
    padding: 5px 0px;
    float: left;
    border: none;
    border-bottom: 1px solid #b7b7b7;
    background: #fff !important;
}

.card-dtls form p select {
    width: 100%;
    margin: 0px 0px 0px;
    padding: 5px 0px;
    float: left;
    border: none;
    border-bottom: 1px solid #b7b7b7;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    background-image: url("../images/arrw.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-color: #fff !important;
}

.fund-bt {
    background-color: #4C6EF8;
    display: block;
    padding: 20px 0px;
    border-radius: 55px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin: 80px auto 0px;
    border: 1px solid #4C6EF8;
    transition: all linear 0.5s;
    width: 100%;
    max-width: 280px;
    transition: all linear 0.5s;
}

.fund-bt:hover {
    background-color: #4C6EF8;
}


.loading-indicator-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    background: rgba(0, 0, 0, .701961);
    -moz-transition: all 250ms linear;
    -o-transition: all 250ms linear;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear
}

.loading-indicator-wrapper.loader-visible {
    opacity: 1;
    z-index: 9999
}

.loading-indicator-wrapper.loader-hidden {
    opacity: 0;
    z-index: -1
}

.loading-indicator-wrapper img {
    vertical-align: middle;
    display: inline-block
}

.loading-indicator-wrapper .loader {
    margin: 0% auto 0px;
    font-size: 10px;
    position: relative;
    display: inline-block;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, .2);
    border-right: 1.1em solid rgba(255, 255, 255, .2);
    border-bottom: 1.1em solid rgba(255, 255, 255, .2);
    border-left: 1.1em solid #fff;
    -webkit-animation: load 1.1s infinite linear;
    animation: load 1.1s infinite linear
}

.loading-indicator-wrapper .loader,
.loading-indicator-wrapper .loader:after {
    border-radius: 50%;
    width: 180px;
    height: 180px;
}

.loading-indicator-wrapper .loading-indicator-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-top: 170px;
}

@-webkit-keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

/* Start smaller screens */

/* Base styles */
.log-btn, .custom-signup-btn {
    background-color: #4C6EF8;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    margin: 5px 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-gradient-div {
    background: linear-gradient(to bottom, #fff 20%, #DBE2FE);
    min-height: 60vh;
    padding: 100px;
    border-radius: 30px;
}

/* Positioning for larger screens */
.logo-lg {
    display: block;
}

.logo-sm {
    display: none;
}

.carousel {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.carousel-inner {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.progress-indicator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;

}

.progress-indicator {
    transition: background-color 1s ease;
}

.custom-prev-btn, .custom-next-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1000;
    padding: 20px;
}

.custom-prev-btn img, .custom-next-btn img{
    width: 40px;
    height: 40px;
}

@media (max-width: 768px) {
    .carousel-inner img {
        height: 20vh;
    }

    .carousel {
        height: auto;
        padding: 20px 10px;
    }

    .carousel-inner {
        margin-top: 60px;
    }

    .carousel-item img {
        max-height: 20vh;
    }
}

/* Media query for larger screens */
@media (min-width: 768px) {
    .col-md-5 {
        padding: 15px;
        position: relative;
    }

    .logo-container {
        margin-bottom: 5px;
        padding-bottom: 0px;
    }

    .logo-container img {
        max-width: 25%;
    }

    .spare-dvs {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .spare-dvs h3 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .log-form {
        margin-top: 5px;
    }
}

/* Media query for smaller screens */
@media (max-width: 767px) {
    .logo-lg {
        display: none;
    }

    .logo-sm {
        display: block;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 10px;
        z-index: 1000;
    }

    .logo-sm img {
        max-width: 30%;
    }

    .logo-container img {
        max-width: 50%;
    }

    .col-md-5, .col-md-7 {
        width: 100%;
        padding: 5px;
    }

    .row.no-gutters.min-vh-100 {
        height: auto;
        display: block;
    }

    .col-md-7 {
        padding: 0;
        min-height: 60vh;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
    }

    .col-md-7 h1, .col-md-7 p {
        font-size: 1.2rem;
        line-height: 1.4;
        margin: 5px 0;
    }

    .log-form.d-md-none {
        display: block;
        margin: 0 auto;
    }

    .log-form.mt-2.d-md-none {
        width: 80%;
        margin: 0 auto;
    }

    .custom-gradient-div {
        min-height: 80vh;
    }

    .button-container {
        padding-top: 20px;
        display: flex;
        justify-content: center;
    }

}

/* End smaller screens */


.m-signature-pad {
    font-size: 10px;
    height: 200px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
    border-radius: 4px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.m-signature-pad--body {
    border: 1px solid #f4f4f4;
}

.m-signature-pad--body
canvas {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}

.m-signature-pad--footer {
    height: 40px;
}

.m-signature-pad--footer
.description {
    color: #C3C3C3;
    text-align: center;
    font-size: 1.2em;
    margin-top: 1.8em;
}

.m-signature-pad--footer
.button {
    bottom: 0;
}

.m-signature-pad--footer
.button.clear {
    left: 0;
}

.m-signature-pad--footer
.button.save {
    right: 0;
}

@media screen and (max-width: 1024px) {
    .m-signature-pad {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        min-width: 250px;
        min-height: 140px;
        margin: 5%;
    }
    #github {
        display: none;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .m-signature-pad {
        margin: 10%;
    }
}

@media screen and (max-height: 320px) {
    .m-signature-pad--body {
        left: 0;
        right: 0;
        top: 0;
        bottom: 32px;
    }
    .m-signature-pad--footer {
        left: 20px;
        right: 20px;
        bottom: 4px;
        height: 28px;
    }
    .m-signature-pad--footer
    .description {
        font-size: 1em;
        margin-top: 1em;
    }
}
